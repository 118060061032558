<template>
	<div class="tableCon">
		<div class="tableConTable">
			<div class="tableConTop">
				<el-row>
					<el-col :span="5" class="tableConTopLeft">
						<h3>
							<el-button type="primary" round icon="el-icon-back" size="mini"
								@click="goBack()">{{$t('ca5a01a5adf20fe7')}}</el-button>
							<span class="tct_tit">日历</span>
						</h3>
					</el-col>
					<el-col :span="19" class="tableConTopRig">

					</el-col>
				</el-row>
			</div>
		</div>
		<el-card style="">
			<el-calendar>
				<!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
				<template slot="dateCell" slot-scope="{date, data}">
					<div>
						<!-- {{date}}<br /> -->

						<!-- {{data.day}}<br /> -->
						<!-- <p :class="data.isSelected ? 'is-selected' : ''"> -->
						{{ data.day.split('-').slice(1).join('-') }}
						<!-- {{ data.isSelected ? '✔️' : ''}} -->
						<!-- </p> -->
						<br />
						<!-- {{fomatterText(data.day)}} -->
						<div v-for="(item,index) in fomatterText(data.day)" :key="index">
							<el-tag v-if="'1'==item.type" plain>
								{{item.name}}
							</el-tag>
							<el-tag v-else-if="'2'==item.type" type="warning" plain>
								{{item.name}}
							</el-tag>
						</div>

					</div>

				</template>
			</el-calendar>
		</el-card>

	</div>
</template>

<script>
	// import whNoAllSelect from '@/components/WarehouseCenter2/components/whNoAllSelect.vue';
	import {
		formatterDate,
		cnHolidayList,
		usHolidayList
	} from '@/components/WarehouseCenter2/Home/utils/WhCalendar.js';

	export default {
		components: {
			// whNoAllSelect
		},
		data() {
			return {
				loading: false,
				cnDayList: [],
				usDayList: [],
			};
		},
		activated() {
			this.initData();
		},
		mounted() {
			// this.initData();
		},
		methods: {
			goBack() {
				this.$router.go(-1);
			},
			initData() {
				this.cnDayList = cnHolidayList();
				this.usDayList = usHolidayList();
				console.log('cnDayList',this.cnDayList);
				console.log('usDayList',this.usDayList);
			},
			fomatterText(date) {
				console.log('fomatterText',date);
				let textList = [];
				let cnDay = this.cnDayList.find(item => {
					// console.log('cnDay find formatterDate(item.date)', formatterDate(item.date));
					return formatterDate(item.date) == date;
				});
				// cnDay && textList.push('CN-'+cnDay.name);
				cnDay && textList.push({
					type: '1',
					name: cnDay.name
				});
				let usDay = this.usDayList.find(item => {
					return formatterDate(item.date) == date;
				});
				usDay && textList.push({
					type: '2',
					name: usDay.name
				});

				// console.log('cnDay',cnDay);
				// console.log('usDay',usDay);

				return textList;
			},
		}
	};
</script>

<style scoped lang="less">


</style>