//yyyy/MM-DD 转化为 yyyy-MM-DD
export const formatterDate = (date) => {
	if(date){
		let dateArr = date.split('/');
		let Y = dateArr[0];
		let M = parseInt(dateArr[1]);
		let D = parseInt(dateArr[2]);
		return Y + '-' + (M < 10 ? '0' + M : M) + '-' + (D < 10 ? '0' + D : D);
	} else {
		return '';
	}
	
}


// 经国务院批准，现将2024年元旦、春节、清明节、劳动节、端午节、中秋节和国庆节放假调休日期的具体安排通知如下。

// 一、元旦：1月1日放假，与周末连休。

// 二、春节：2月10日至17日放假调休，共8天。2月4日（星期日）、2月18日（星期日）上班。鼓励各单位结合带薪年休假等制度落实，安排职工在除夕（2月9日）休息。

// 三、清明节：4月4日至6日放假调休，共3天。4月7日（星期日）上班。

// 四、劳动节：5月1日至5日放假调休，共5天。4月28日（星期日）、5月11日（星期六）上班。

// 五、端午节：6月10日放假，与周末连休。

// 六、中秋节：9月15日至17日放假调休，共3天。9月14日（星期六）上班。

// 七、国庆节：10月1日至7日放假调休，共7天。9月29日（星期日）、10月12日（星期六）上班。
export const cnHolidayList = () => {
	let year = new Date().getFullYear();
	
	return [{
		// date: '2024/1/1',
		date: `${year}/1/1`,
		name: '元旦',
	}, {
		date: '2024/2/4',
		name: '春节补班',
	}, {
		date: '2024/2/10',
		name: '春节',
	}, {
		date: '2024/2/11',
		name: '春节',
	}, {
		date: '2024/2/12',
		name: '春节',
	}, {
		date: '2024/2/13',
		name: '春节',
	}, {
		date: '2024/2/14',
		name: '春节',
	}, {
		date: '2024/2/15',
		name: '春节',
	}, {
		date: '2024/2/16',
		name: '春节',
	}, {
		date: '2024/2/17',
		name: '春节',
	}, {
		date: '2024/2/18',
		name: '春节补班',
	}, {
		date: '2024/4/4',
		name: '清明节',
	}, {
		date: '2024/4/5',
		name: '清明节',
	}, {
		date: '2024/4/6',
		name: '清明节',
	}, {
		date: '2024/4/7',
		name: '清明补班',
	}, {
		date: '2024/4/28',
		name: '劳动节补班',
	}, {
		// date: '2024/5/1',
		date: `${year}/5/1`,
		name: '劳动节',
	}, {
		date: '2024/5/2',
		name: '劳动节',
	}, {
		date: '2024/5/3',
		name: '劳动节',
	}, {
		date: '2024/5/4',
		name: '劳动节',
	}, {
		date: '2024/5/5',
		name: '劳动节',
	}, {
		date: '2024/5/11',
		name: '劳动节补班',
	}, {
		date: '2024/6/10',
		name: '端午节',
	}, {
		date: '2024/9/14',
		name: '中秋节补班',
	}, {
		date: '2024/9/15',
		name: '中秋节',
	}, {
		date: '2024/9/16',
		name: '中秋节',
	}, {
		date: '2024/9/17',
		name: '中秋节',
	}, {
		date: '2024/9/29',
		name: '国庆节补班',
	}, {
		// date: '2024/10/1',
		date: `${year}/10/1`,
		name: '国庆节',
	}, {
		date: '2024/10/2',
		name: '国庆节',
	}, {
		date: '2024/10/3',
		name: '国庆节',
	}, {
		date: '2024/10/4',
		name: '国庆节',
	}, {
		date: '2024/10/5',
		name: '国庆节',
	}, {
		date: '2024/10/6',
		name: '国庆节',
	}, {
		date: '2024/10/7',
		name: '国庆节',
	}, {
		date: '2024/10/12',
		name: '国庆节补班',
	}];
}
export const usHolidayList = () => {
	return getFixedFestival();
}

//固有节日节假日的时间戳
export const getFixedFestival = function() {
	let year = new Date().getFullYear();
	//新年1.1 -- 放一天
	// 7月4日 独立日（Independence Day） --放一天
	// 11.11 老兵节
	//圣诞节  -- 12.25 放两天
	//以及周末
	let stringArr = [];
	// stringArr.push(new Date(`${year}/1/1`).getTime());
	// stringArr.push(new Date(`${year}/7/4`).getTime());
	// stringArr.push(new Date(`${year}/11/11`).getTime() );
	// stringArr.push(new Date(`${year}/12/25`).getTime()  );
	// stringArr.push(new Date(`${year}/12/26`).getTime());
	// stringArr.push(new Date(getMartinLutherKingDay()).getTime());
	// stringArr.push(new Date(getPresidentDay()).getTime());
	// stringArr.push(new Date(getMemorialDay()).getTime());
	// stringArr.push(new Date(getLaborDay()).getTime());
	// stringArr.push(new Date(getColumbusDay()).getTime());
	// stringArr.push(new Date(getThanksGivingDay()).getTime());

	stringArr.push({
		date: `${year}/1/1`,
		name: '新年元旦'
	});
	stringArr.push({
		date: `${year}/7/4`,
		name: '独立日'
	});
	stringArr.push({
		date: `${year}/11/11`,
		name: '老兵节'
	});
	stringArr.push({
		date: `${year}/12/25`,
		name: '圣诞节'
	});
	stringArr.push({
		date: `${year}/12/26`,
		name: '圣诞节'
	});
	stringArr.push(getMartinLutherKingDay());
	stringArr.push(getPresidentDay());
	stringArr.push(getMemorialDay());
	stringArr.push(getLaborDay());
	stringArr.push(getColumbusDay());
	stringArr.push(getThanksGivingDay());

	return stringArr;
}

//计算马丁路德金纪念日 -- 一月份第三个星期一 放假一天
export const getMartinLutherKingDay = function() {
	return getMonthDay(1, 3, 1, '马丁路德金纪念日');
}

//计算总统日 -- 二月份第三个星期一 -- 放一天
export const getPresidentDay = function() {
	return getMonthDay(2, 3, 1, '总统日');
}

// //计算阵亡将士纪念日 -- （五月的最后一个星期一）  放假一天
export const getMemorialDay = function() {
	//返回该月最后一个周一的日期
	let day = getLastMondayOfMonthDay(2024,4,'阵亡将士纪念日');//月是从0计数；
	// return getMonthDay(5, -1, 1, '阵亡将士纪念日');
	// return getMonthDay(5, 4, 1, '阵亡将士纪念日');
	console.log('day',day);
	return day;
}

// //计算劳动日 -- 九月的第一个星期一） 放假一天
export const getLaborDay = function() {
	return getMonthDay(9, 1, 1, '劳动日');
}

//计算哥伦布日 ---十月第二个星期一 放假一天
export const getColumbusDay = function() {
	return getMonthDay(10, 2, 1, '哥伦布日');
}

//计算感恩节 -- 十一月第四个星期天 
export const getThanksGivingDay = function() {
	return getMonthDay(11, 4, 4, '感恩节');
}

//计算这个月的第几个星期几 是位于这个月的多少号
/**
 * @param {month} 月份
 * @param {num} 第几个,如果num= -1 就表示最后一个
 * @param {day} 星期几
 */
export const getMonthDay = function(month , num , day , name) {
	if (month < 1 || month > 12) {
		console.log("参数不合法")
		return
	}
	if (num <= 0 && num != -1) {
		console.log("参数不合法")
		return
	}
	if (day < 0 || day > 6) {
		console.log("参数不合法")
		return
	}
	let dateString = '';
	//当前的年份
	let year = new Date().getFullYear();
	//当前年份，当前月份的第一天的星期数
	let firstDateDay = new Date(`${year}/${month}/1`).getDay();
	//这个月的天数
	let monthTotalDay = getMonthDayNum(month);
	// //这个月的星期数 
	// let weekNum = Math.floor(monthTotalDay/7);
	//整的星期数余下的天数
	let weekNumRemainder = monthTotalDay % 7;
	//要计算的星期与本月1号的星期的差值
	let minusDay = Math.abs(firstDateDay - day);
	//达到这个月的第一个目的星期需要的天数
	let dayNum = firstDateDay < day ? day - firstDateDay : (firstDateDay > day ? 7 - firstDateDay + day : 0);
	// if (num == -1) {
	// 	//部分解析  monthTotalDay 是总天数，dayNum + 1 是到达一个星期数的日期，除以整周，向下取整就是剩余的周数 ,算出后再加上之前的一周
	// 	let targetWeeekDayNum = Math.floor(monthTotalDay - (dayNum + 1) * 7) + 1;
	// 	return getMonthDay(month, targetWeeekDayNum, day,name)
	// 	// dateString = `${year}/${month}/${num > 1 ? (num - 1)*7 + dayNum + 1 : dayNum+1}`
	// }
	//这里的加1是时间的差值加上这个月的一号，表示当前的号数
	if ((num - 1) * 7 + dayNum + 1 > monthTotalDay) {
		console.log("已经超出本月的范围")
	} else {
		dateString = `${year}/${month}/${num > 1 ? (num - 1)*7 + dayNum + 1 : dayNum+1}`
	}
	return {
		date: dateString,
		name: name
	};
}

	/**
 * @param {year} 当前的年份
 * @param {month} 当前的月份
*/
//获取这个月有多少天
export const getMonthDayNum = function (year = 2020,month = 1) { 
  if(month < 1 || month > 12){
    console.log("参数不合法")
    return 
  }
  let num = 28;
  switch(month){
    case 1:
      num = 31;break;
    //计算闰年的方式
    case 2:
      if(year%4 == 0 ||  (year % 400 == 0 && year % 100 == 0)){
        num = 29
      }else{
        num = 28
      }  
    ;break;
    case 3:
      num = 31;break;
    case 4:
      num = 30;break;
    case 5:
      num = 31;break;
    case 6:
      num = 30;break;
    case 7:
      num = 31;break;
    case 8:
      num = 31;break;
    case 9:
      num = 30;break;
    case 10:
      num = 31;break;
    case 11:
      num = 30;break;
    case 12:
      num = 31;break;
    default:
      num = 28;break;
  }
  return num;//默认就是28
}

/*计算某月最后一个周一的日期*/
function getLastMondayOfMonthDay(year, month,name) {
    // 获取下个月的第一天
    const nextMonthFirstDay = new Date(year, month + 1, 1);
    // 回退到本月最后一天
    const thisMonthLastDay = new Date(nextMonthFirstDay - 1);
    
    // 找到最后一周的星期一
    const lastMonday = new Date(thisMonthLastDay);
    lastMonday.setDate(thisMonthLastDay.getDate() - (thisMonthLastDay.getDay() + 6) % 7);
    let day = lastMonday.getDate();
    // return `${year}/${month+1}/${day}`;
		
		return {
			date: `${year}/${month+1}/${day}`,
			name: name
		};
}

